$black: var(--black);

$grey-800: var(--grey-800);
$grey-600: var(--grey-600);
$grey-400: var(--grey-400);
$grey-300: var(--grey-300);
$grey-180: var(--grey-180);
$grey-080: var(--grey-080);
$grey-050: var(--grey-050);
$grey-020: var(--grey-020);

$teal-800: var(--teal-800);
$teal-500: var(--teal-500);
$teal-300: var(--teal-300);
$teal-100: var(--teal-100);
$teal-050: var(--teal-050);

$indigo-800: var(--indigo-800);
$indigo-500: var(--indigo-500);
$indigo-300: var(--indigo-300);
$indigo-100: var(--indigo-100);
$indigo-050: var(--indigo-050);

$pink-800: var(--pink-800);
$pink-500: var(--pink-500);
$pink-300: var(--pink-300);
$pink-100: var(--pink-100);
$pink-050: var(--pink-050);

$yellow-800: var(--yellow-800);
$yellow-500: var(--yellow-500);
$yellow-300: var(--yellow-300);
$yellow-100: var(--yellow-100);
$yellow-050: var(--yellow-050);

$orange-800: var(--orange-800);
$orange-500: var(--orange-500);
$orange-300: var(--orange-300);
$orange-100: var(--orange-100);
$orange-050: var(--orange-050);

$green-800: var(--green-800);
$green-500: var(--green-500);
$green-300: var(--green-300);
$green-100: var(--green-100);
$green-050: var(--green-050);

$red-800: var(--red-800);
$red-600: var(--red-600);
$red-400: var(--red-400);
$red-300: var(--red-300);
$red-100: var(--red-100);
$red-050: var(--red-050);

$primary-color-800: var(--primary-color-800);
$primary-color-500: var(--primary-color-500);
$primary-color-300: var(--primary-color-300);
$primary-color-100: var(--primary-color-100);
$primary-color-050: var(--primary-color-050);

$primary-color: var(--primary-color);
$primary-color-darker: var(--primary-color-darker);

$link-primary-color: var(--link-primary-color);
$link-primary-color-darker: var(--link-primary-color-darker);

$danger-color: var(--danger-color);
$success-color: var(--success-color);

$facebook-color: var(--facebook-color);
$twitter-color: var(--twitter-color);
$youtube-color: var(--youtube-color);
$snapchat-color: var(--snapchat-color);
$tiktok-color: var(--tiktok-color);
$weibo-color: var(--weibo-color);

$instagram-posts-color: var(--instagram-posts-color);
$instagram-stories-color: var(--instagram-stories-color);

$top-color: var(--top-color);
$macro-color: var(--macro-color);
$micro-color: var(--micro-color);
$nano-color: var(--nano-color);
$mega-color: var(--mega-color);

@mixin lefty-colors-variables {
  --black-050: rgba(0, 0, 0, 0.5);
  --black-008: rgba(0, 0, 0, 0.08);
  --black: black;

  --grey-800: #333;
  --grey-600: #666;
  --grey-400: #999;
  --grey-300: #b2b2b2;
  --grey-180: #d1d1d1;
  --grey-080: #ebebeb;
  --grey-050: #f5f5f5;
  --grey-020: #fafafa;

  --teal-800: #00606a;
  --teal-500: #0097a7;
  --teal-300: #70c5ce;
  --teal-100: #c3ebf0;
  --teal-050: #e6f8fa;

  --indigo-800: #202e78;
  --indigo-500: #5c6ac4;
  --indigo-300: #a4aef0;
  --indigo-100: #d7dcfc;
  --indigo-050: #f0f2ff;

  --pink-800: #9b1443;
  --pink-500: #c54e77;
  --pink-300: #ef91b2;
  --pink-100: #ffd0e0;
  --pink-050: #ffecf3;

  --yellow-800: #aa7700;
  --yellow-500: #fab005;
  --yellow-300: #ffd84e;
  --yellow-100: #ffefa8;
  --yellow-050: #fff9db;

  --orange-800: #b14c0e;
  --orange-500: #f49342;
  --orange-300: #ffbf82;
  --orange-100: #ffe0c2;
  --orange-050: #fff2e5;

  --green-800: #008a36;
  --green-500: #2bc168;
  --green-300: #76dd9f;
  --green-100: #c0eed2;
  --green-050: #e7f9ef;

  --red-800: #9d2121;
  --red-600: #db3939;
  --red-600-040: rgba(219, 57, 57, 0.4);
  --red-400: #e25f5f;
  --red-300: #f99292;
  --red-100: #ffd3d3;
  --red-050: #fff1f1;

  --accent-color: #e0e05c;

  --primary-color-800: var(--teal-800);
  --primary-color-500: var(--teal-500);
  --primary-color-300: var(--teal-300);
  --primary-color-100: var(--teal-100);
  --primary-color-050: var(--teal-050);

  --primary-color: var(--primary-color-500);
  --primary-color-darker: var(--primary-color-800);

  --link-primary-color: var(--primary-color);
  --link-primary-color-darker: var(--primary-color-darker);

  --base-background-color: #f7f5f2;
  --base-border-color: var(--grey-180);

  --content-background-color: white;
  --content-border-color: var(--base-border-color);
  --content-border-radius: 12px;

  --body-background-color: var(--base-background-color);

  --bar-charts-color: var(--primary-color-300);
  --ghost-rgb-color: 245, 245, 245;

  --facebook-color: #4267b2;
  --twitter-color: #1b95e0;
  --youtube-color: #d9252a;
  --snapchat-color: #fffc00;
  --tiktok-color: #000000;
  --weibo-color: #ff8140;

  --instagram-posts-color: #d4098d;
  --instagram-stories-color: var(--orange-300);

  --top-color: var(--teal-300);
  --macro-color: var(--indigo-500);
  --micro-color: var(--orange-300);
  --nano-color: var(--red-300);
  --mega-color: var(--teal-800);

  --danger-color: var(--red-600);
  --danger-color-040: var(--red-600-040);
  --success-color: var(--green-500);
}
