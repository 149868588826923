@use 'sass:math';

@use '../grid/gridlex' as gridlex;
@use '../variables' as vars;
@use '../mixins' as mixins;

.flex {
  display: flex;

  &.align-start {
    align-items: flex-start;
  }

  &.align-end {
    align-items: flex-end;
  }

  &.justify-start {
    justify-content: flex-start;
  }

  &.justify-end {
    justify-content: flex-end;
  }

  &.column {
    flex-direction: column;

    &.reverse {
      flex-direction: column-reverse;
    }

    &.hCenter {
      align-items: center;
    }

    &.vCenter {
      justify-content: center;
    }

    &.space-between {
      align-items: space-between;
    }
  }

  &.row {
    &.reverse {
      flex-direction: row-reverse;
    }

    &.hCenter {
      justify-content: center;
    }

    &.vCenter {
      align-items: center;
    }

    &.space-between {
      justify-content: space-between;
    }
  }

  &.wrap {
    flex-wrap: wrap;
  }

  &.wrap-reverse {
    flex-wrap: wrap-reverse;
  }
}

.fill-space,
.flex-1 {
  flex: 1;
}

@mixin _content-grid-fit-width {
  width: 100%;
  max-width: none;
  padding-right: vars.$grid-layout-padding;
  padding-left: vars.$grid-layout-padding;
}

@mixin _content-grid-col($number) {
  width: 100%;
  max-width: $number * vars.$grid-item-width + ($number - 1) *
    gridlex.$gl-gutter;
}

/// Layout class following design styleguide
/// https://docs.google.com/presentation/d/1lyELpUxAno7hjUKl6J47qSaGoqTNmQFx9lREyLf6JKw/edit#slide=id.g6c98445ee1_0_0
.lefty-layout {
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 120px;

  // default layout is on 3 columns width
  @include _content-grid-col(3);

  // large layout is on 4 columns width (discover)
  &.large {
    @include _content-grid-col(4);
  }

  // take full width of the screen
  &.wide {
    @include _content-grid-fit-width;
  }

  &.no-padding-bottom {
    padding-bottom: 0 !important;
  }

  &.mobile {
    padding-right: vars.$grid-space-4 !important;
    padding-left: vars.$grid-space-4 !important;
  }
}

.page-header {
  display: flex;
  align-items: center;
  height: 32px;

  margin-top: vars.$grid-space-6;
  margin-bottom: vars.$grid-space-5;

  &.mobile {
    margin-top: vars.$grid-space-5 !important;
  }

  &.heading-1 {
    margin-top: vars.$grid-space-7;
    margin-bottom: vars.$grid-space-6;
  }

  .actions {
    .action {
      margin-left: vars.$grid-space-2;
    }
  }

  // Add margin bottom to keep the ghost animation align with
  // the text in h1 and h2
  //
  // For esthetic reason we don't use the same height on ghost animation
  // so we need to play with margin
  &.loading > .label {
    // h2,.label font size is 24px
    // ghost height is 20px
    margin-bottom: 4px;
  }
}

.page-subheader {
  display: flex;
  margin-bottom: vars.$grid-space-5;

  &.media-list {
    margin-bottom: vars.$grid-space-4;
  }

  &:not(.columns) {
    align-items: center;
  }

  &.columns {
    flex-direction: column;

    .first-row {
      display: flex;
    }
  }

  .actions {
    .action {
      margin-left: vars.$grid-space-2;
    }
  }
}

.page-header,
.page-subheader {
  & > h1,
  & > h2,
  & > h3 {
    text-transform: capitalize;
  }

  &.loading > .label,
  &.loading > h1,
  &.loading > h2,
  &.loading > h3 {
    @include mixins.ghost-animation;
    border-radius: 6px;
    height: 20px;
    width: 200px;
  }

  &.loading > h1 {
    // h1 font size is 30px
    // ghost height is 20px
    margin-bottom: vars.$grid-space-1;
    margin-top: vars.$grid-space-1;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
  }
}

// when screen width is less than 1590px
@include gridlex.bp('lg') {
  .lefty-layout {
    // compact layout goes on 2 columns width
    &.compact {
      @include _content-grid-col(2);
    }

    // responsive layout fit to the available width
    &.large,
    &.compact {
      &.responsive {
        @include _content-grid-fit-width;
      }
    }
  }

  .col-table-filter {
    flex-basis: math.percentage(math.div(1, 5));
    min-width: math.percentage(math.div(1, 5));
  }

  .col-filter {
    flex-basis: math.percentage(math.div(1, 4));
    min-width: math.percentage(math.div(1, 4));
  }
}

// when screen width is less than 1200px
@include gridlex.bp('md') {
  .lefty-layout {
    // default layout goes on 2 columns width
    @include _content-grid-col(2);

    // compact layout or any responsive layout take full width
    &.responsive,
    &.compact {
      @include _content-grid-fit-width;
    }

    // large layout goes on 3 columns width
    &.large:not(.responsive) {
      @include _content-grid-col(3);
    }
  }

  .col-table-filter,
  .col-filter {
    flex-basis: math.percentage(math.div(1, 3));
    min-width: math.percentage(math.div(1, 3));
  }
}

// when screen width is less than 810px
@include gridlex.bp('xs') {
  .lefty-layout {
    // default layout take full width
    @include _content-grid-fit-width;

    // large layout goes on 2 columns width
    &.large:not(.responsive) {
      @include _content-grid-col(2);
    }
  }
}

[#{gridlex.$gl-attributeName}~='#{gridlex.$gl-gridName}'],
[#{gridlex.$gl-attributeName}*='#{gridlex.$gl-gridName}-'],
[#{gridlex.$gl-attributeName}*='#{gridlex.$gl-gridName}_'] {
  &.cards-grid > {
    [#{gridlex.$gl-attributeName}~='#{gridlex.$gl-colName}'],
    [#{gridlex.$gl-attributeName}*='#{gridlex.$gl-colName}-'],
    [#{gridlex.$gl-attributeName}*='#{gridlex.$gl-colName}_'] {
      padding: 0 math.div(gridlex.$gl-gutter, 2) gridlex.$gl-gutter;
    }
  }

  &.compact >,
  &.mobile > {
    [#{gridlex.$gl-attributeName}~='#{gridlex.$gl-colName}'],
    [#{gridlex.$gl-attributeName}*='#{gridlex.$gl-colName}-'],
    [#{gridlex.$gl-attributeName}*='#{gridlex.$gl-colName}_'] {
      padding: 0 math.div(vars.$grid-space-4, 2) vars.$grid-space-4;
    }
  }
}
