@use './grid' as *;

$tooltip-border-radius: 8px;
$tooltip-padding: $grid-space-2 $grid-space-3;
$tooltip-max-width: 290px;
$tooltip-max-width-large: 420px;
$tooltip-shadow:
  0px 3px 10px rgba(0, 0, 0, 0.08),
  0px 0px 1px rgba(0, 0, 0, 0.16);
$tooltip-transition: opacity 300ms;
