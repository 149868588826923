@mixin ghost-animation {
  background-color: #fff;
  background-image: linear-gradient(
    90deg,
    rgba(var(--ghost-rgb-color), 0.8) 0px,
    rgba(var(--ghost-rgb-color), 0.3) 40px,
    rgba(var(--ghost-rgb-color), 0.8) 80px
  );
  background-size: 100vw;
  animation: ghost-animation 1500ms infinite linear;
  color: transparent !important;
}
