@forward 'packages/lefty-assets/src/lib/css/lefty.scss' with (
  $gl-mq-list: (
    lg: 1439px,
    md: 1239px,
    sm: 1023px,
    xs: 767px,
  )
  !default
);

@use 'packages/lefty-assets/src/lib/css/nestor-theme' as nestor;
@forward '../../../node_modules/flag-icons/css/flag-icons.min.css';

:root {
  @include nestor.nestor-theme;
}
