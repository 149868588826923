@use './colors' as *;

@mixin scrollbar-css-variables {
  --scrollbar-size: 15px;
  --scrollbar-thumb-border: 3px;
  --scrollbar-thumb-radius: 8px;
  --scrollbar-thumb-min-size: 68px;
  --scrollbar-background-color: var(--grey-050);
  --scrollbar-background-color-hover: var(--grey-050);
  --scrollbar-thumb-color: var(--grey-300);
  --scrollbar-thumb-color-hover: var(--grey-400);
}

$scrollbar-size: var(--scrollbar-size);
$scrollbar-thumb-border: var(--scrollbar-thumb-border);
$scrollbar-thumb-radius: var(--scrollbar-thumb-radius);
$scrollbar-thumb-min-size: var(--scrollbar-thumb-min-size);
$scrollbar-background-color: var(--scrollbar-background-color);
$scrollbar-background-color-hover: var(--scrollbar-background-color-hover);
$scrollbar-thumb-color: var(--scrollbar-thumb-color);
$scrollbar-thumb-color-hover: var(--scrollbar-thumb-color-hover);
