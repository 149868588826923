@use '../mixins/rem' as *;

$font: var(--font);

$fancy-font: var(--fancy-font);

@mixin lefty-fonts-variables {
  --font: Inter;
  --font-weight-semi-bold: 600;
  --font-weight-medium: 500;
  --font-weight-regular: 400;
  --font-weight-light: 300;

  --fancy-font: 'Marcellus';

  --heading-font-weight: var(--font-weight-medium);
  --heading-font-color: var(--black);

  --heading-1-font-size: #{rem(28px)};
  --heading-1-line-height: #{rem(36px)};
  --heading-1-font-weight: var(--heading-font-weight);

  --heading-2-font-size: #{rem(22px)};
  --heading-2-line-height: #{rem(20px)};
  --heading-2-font-weight: var(--heading-font-weight);

  --heading-3-font-size: #{rem(18px)};
  --heading-3-line-height: #{rem(28px)};
  --heading-3-font-weight: var(--heading-font-weight);

  --heading-4-font-size: #{rem(14px)};
  --heading-4-line-height: #{rem(20px)};
  --heading-4-font-weight: var(--heading-font-weight);

  --heading-5-font-size: #{rem(14px)};
  --heading-5-line-height: #{rem(20px)};
  --heading-5-font-weight: var(--heading-font-weight);

  --heading-6-font-size: #{rem(14px)};
  --heading-6-line-height: #{rem(20px)};
  --heading-6-font-weight: var(--heading-font-weight);

  --body-1-font-weight: var(--font-weight-regular);
  --body-1-line-height: #{rem(20px)};
  --body-1-min-line-height: #{rem(16px)};
  --body-1-font-size: #{rem(14px)};
  --body-1-font-color: var(--black);

  --caption-1-font-weight: var(--font-weight-regular);
  --caption-1-line-height: #{rem(16px)};
  --caption-1-font-size: #{rem(12px)};
  --caption-1-font-color: var(--black);

  --paragraph-font-color: var(--black);

  --paragraph-x-large-font-size: #{rem(18px)};
  --paragraph-x-large-line-height: #{rem(28px)};
  --paragraph-x-large-font-weight: var(--font-weight-regular);

  --paragraph-large-font-size: #{rem(16px)};
  --paragraph-large-line-height: #{rem(24px)};
  --paragraph-large-font-weight: var(--font-weight-regular);

  --paragraph-medium-font-size: #{rem(14px)};
  --paragraph-medium-line-height: #{rem(20px)};
  --paragraph-medium-font-weight: var(--font-weight-regular);

  --paragraph-small-font-size: #{rem(12px)};
  --paragraph-small-line-height: #{rem(16px)};
  --paragraph-small-font-weight: var(--font-weight-regular);
}
