@use '../variables/card' as *;

@mixin al-card(
  $with-shadow: false,
  $with-padding: false,
  $shadow-hover: false,
  $with-border: false
) {
  background-color: #fff;
  border-radius: $al-card-border-radius;
  box-sizing: border-box;
  transition: $al-card-transition;
  overflow: hidden;

  @if $with-shadow==true {
    box-shadow: $al-card-box-shadow;

    @if $shadow-hover==true {
      &:hover {
        box-shadow: $al-card-box-shadow-hover;
      }
    }
  }

  @if $with-padding==true {
    padding: $al-card-padding;
  }

  @if $with-border==true {
    border: $al-card-border;
  }
}
