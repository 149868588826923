@forward './variables/colors';
@forward './variables/grid';
@forward './variables/fonts';
@forward './variables/input';
@forward './variables/select';
@forward './variables/card';
@forward './variables/buttons';
@forward './variables/shadow';
@forward './variables/popup';
@forward './variables/chips';
@forward './variables/list';
@forward './variables/badge';
@forward './variables/tooltip';
@forward './variables/slider';
@forward './variables/spinner';
@forward './variables/progress';
@forward './variables/icon';
@forward './variables/dialog';
@forward './variables/scrollbar';
@forward './variables/feedback';
@forward './variables/table';
@forward './variables/ai';
@forward './variables/checkbox';
@forward './variables/radio';
@forward './variables/toggle';
@forward './variables/slider';
@forward './variables/grid';

@use './variables/grid';
@use './variables/fonts';
@use './variables/colors';
@use './variables/input';
@use './variables/buttons';
@use './variables/shadow';
@use './variables/popup';
@use './variables/badge';
@use './variables/scrollbar';
@use './variables/feedback';
@use './variables/table';
@use './variables/ai';
@use './variables/checkbox';
@use './variables/radio';
@use './variables/toggle';
@use './variables/slider';
@use './variables/menu';

$influencer-navs-height: 52px;
$top-bar-height: 66px;
$left-bar-width: 60px;
$left-bar-compact-width: 50px;

$status-contacted: (
  name: 'state_contacted',
  primary-color: var(--teal-500),
  bg-color: var(--teal-050),
  bg-color-hover: var(--teal-100),
  border-color: var(--teal-300),
);

$status-discussion: (
  name: 'state_in_discussion',
  primary-color: var(--indigo-500),
  bg-color: var(--indigo-050),
  bg-color-hover: var(--indigo-100),
  border-color: var(--indigo-300),
);

$status-waiting: (
  name: 'state_waiting',
  primary-color: var(--orange-500),
  bg-color: var(--orange-050),
  bg-color-hover: var(--orange-100),
  border-color: var(--orange-300),
);

$status-accepted: (
  name: 'state_accepted',
  primary-color: var(--green-500),
  bg-color: var(--green-050),
  bg-color-hover: var(--green-100),
  border-color: var(--green-300),
);

$status-paid: (
  name: 'state_paid',
  primary-color: var(--yellow-500),
  bg-color: var(--yellow-050),
  bg-color-hover: var(--yellow-100),
  border-color: var(--yellow-300),
);

$status-rejected: (
  name: 'state_rejected',
  primary-color: var(--red-400),
  bg-color: var(--red-050),
  bg-color-hover: var(--red-100),
  border-color: var(--red-300),
);

$influencer-status: $status-contacted, $status-discussion, $status-waiting,
  $status-accepted, $status-paid, $status-rejected;

$profile-bg-image: '/lefty-images/bg-avatar.png';

$lefty-form-layout-helper-width: 350px;
$lefty-form-layout-gap: grid.$grid-space-6;

@mixin lefty-variables {
  @include colors.lefty-colors-variables;
  @include fonts.lefty-fonts-variables;
  @include shadow.lefty-shadow-variables;
  @include buttons.lefty-button-variables;
  @include input.lefty-input-variables;
  @include badge.lefty-badge-variables;
  @include popup.lefty-popup-variables;
  @include scrollbar.scrollbar-css-variables;
  @include table.lefty-table-variables;
  @include ai.lefty-ai-variables;
  @include feedback.lefty-feedback-variables;
  @include checkbox.lefty-checkbox-variables;
  @include radio.lefty-radio-variables;
  @include toggle.lefty-toggle-variables;
  @include slider.lefty-slider-variables;
  @include menu.lefty-menu-variables;

  --grid-layout-padding: 50px;
  --grid-layout-padding-bottom: 120px;
  --grid-layout-max-width: 1140px;
  --lefty-card-border-radius: 12px;
  --lefty-selectable-card-border-radius: 8px;

  --networks-icons-gap: #{grid.$grid-space-2};
  --networks-icons-minimal-gap: 2px;

  --focus-outline-offset: 1px;
  --focus-outline: solid 2px var(--primary-color-300);

  --profile-pic-x-small-size: 24px;
  --profile-pic-small-size: 32px;
  --profile-pic-medium-size: 40px;
  --profile-pic-large-size: 80px;
}
