@use 'sass:math';
@use 'gridlex-vars' as vars;
@use 'gridlex-mixins' as mixins;

//************************
//    CLASSES
//************************
// GRID
[#{vars.$gl-attributeName}~='#{vars.$gl-gridName}'],
[#{vars.$gl-attributeName}*='#{vars.$gl-gridName}-'],
[#{vars.$gl-attributeName}*='#{vars.$gl-gridName}_'] {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  margin: 0 math.div(-(vars.$gl-gutter), 2);
}
// COLS
[#{vars.$gl-attributeName}~='#{vars.$gl-colName}'],
[#{vars.$gl-attributeName}*='#{vars.$gl-colName}-'],
[#{vars.$gl-attributeName}*='#{vars.$gl-colName}_'] {
  box-sizing: border-box;
  padding: 0 math.div(vars.$gl-gutter, 2) vars.$gl-gutter-vertical;
  max-width: 100%;
}
// JUST "COL" & "COL_"
[#{vars.$gl-attributeName}~='#{vars.$gl-colName}'],
[#{vars.$gl-attributeName}*='#{vars.$gl-colName}_'] {
  flex: 1 1 0%;
}
// JUST "COL-"
[#{vars.$gl-attributeName}*='#{vars.$gl-colName}-'] {
  flex: none;
}

// COL & GRID
[#{vars.$gl-attributeName}~='#{vars.$gl-gridName}'][#{vars.$gl-attributeName}~='#{vars.$gl-colName}'],
[#{vars.$gl-attributeName}~='#{vars.$gl-gridName}'][#{vars.$gl-attributeName}*='#{vars.$gl-colName}-'],
[#{vars.$gl-attributeName}~='#{vars.$gl-gridName}'][#{vars.$gl-attributeName}*='#{vars.$gl-colName}_'],
[#{vars.$gl-attributeName}*='#{vars.$gl-gridName}-'][#{vars.$gl-attributeName}~='#{vars.$gl-colName}'],
[#{vars.$gl-attributeName}*='#{vars.$gl-gridName}-'][#{vars.$gl-attributeName}*='#{vars.$gl-colName}-'],
[#{vars.$gl-attributeName}*='#{vars.$gl-gridName}-'][#{vars.$gl-attributeName}*='#{vars.$gl-colName}_'],
[#{vars.$gl-attributeName}*='#{vars.$gl-gridName}_'][#{vars.$gl-attributeName}~='#{vars.$gl-colName}'],
[#{vars.$gl-attributeName}*='#{vars.$gl-gridName}_'][#{vars.$gl-attributeName}*='#{vars.$gl-colName}-'],
[#{vars.$gl-attributeName}*='#{vars.$gl-gridName}_'][#{vars.$gl-attributeName}*='#{vars.$gl-colName}_'] {
  margin: 0;
  padding: 0;
}

/************************
    HELPERS SUFFIXES
*************************/
// FOR GRID
[#{vars.$gl-attributeName}*='#{vars.$gl-gridName}-'] {
  // No spacing between cols : noGutter
  &[#{vars.$gl-attributeName}*='-noGutter'] {
    margin: 0;
    > [#{vars.$gl-attributeName}~='#{vars.$gl-colName}'],
    > [#{vars.$gl-attributeName}*='#{vars.$gl-colName}-'] {
      padding: 0;
    }
  }

  // No Wrapping
  &[#{vars.$gl-attributeName}*='-noWrap'] {
    flex-wrap: nowrap;
  }
  // Horizontal alignment on center
  &[#{vars.$gl-attributeName}*='-center'] {
    justify-content: center;
  }
  // Horizontal alignment on right
  &[#{vars.$gl-attributeName}*='-right'] {
    justify-content: flex-end;
    align-self: flex-end;
    margin-left: auto;
  }
  // Vertical alignment on top
  &[#{vars.$gl-attributeName}*='-top'] {
    align-items: flex-start;
  }
  // Vertical alignment on middle
  &[#{vars.$gl-attributeName}*='-middle'] {
    align-items: center;
  }
  // Vertical alignment on bottom
  &[#{vars.$gl-attributeName}*='-bottom'] {
    align-items: flex-end;
  }

  // Orders
  &[#{vars.$gl-attributeName}*='-reverse'] {
    flex-direction: row-reverse;
  }
  &[#{vars.$gl-attributeName}*='-column'] {
    flex-direction: column;
    > [#{vars.$gl-attributeName}*='#{vars.$gl-colName}-'] {
      flex-basis: auto;
    }
  }
  &[#{vars.$gl-attributeName}*='-column-reverse'] {
    flex-direction: column-reverse;
  }

  // Spaces between and around cols
  &[#{vars.$gl-attributeName}*='-spaceBetween'] {
    justify-content: space-between;
  }
  &[#{vars.$gl-attributeName}*='-spaceAround'] {
    justify-content: space-around;
  }

  // Equal heights columns
  &[#{vars.$gl-attributeName}*='-equalHeight']
    > [#{vars.$gl-attributeName}~='#{vars.$gl-colName}'],
  &[#{vars.$gl-attributeName}*='-equalHeight']
    > [#{vars.$gl-attributeName}*='#{vars.$gl-colName}-'],
  &[#{vars.$gl-attributeName}*='-equalHeight']
    > [#{vars.$gl-attributeName}*='#{vars.$gl-colName}_'] {
    align-self: stretch;
    > * {
      height: 100%;
    }
  }
  // Removes the padding-bottom
  &[#{vars.$gl-attributeName}*='-noBottom']
    > [#{vars.$gl-attributeName}~='#{vars.$gl-colName}'],
  &[#{vars.$gl-attributeName}*='-noBottom']
    > [#{vars.$gl-attributeName}*='#{vars.$gl-colName}-'],
  &[#{vars.$gl-attributeName}*='-noBottom']
    > [#{vars.$gl-attributeName}*='#{vars.$gl-colName}_'] {
    padding-bottom: 0;
  }
}

// FOR COL
[#{vars.$gl-attributeName}*='#{vars.$gl-colName}-'] {
  &[#{vars.$gl-attributeName}*='-top'] {
    align-self: flex-start;
  }
  &[#{vars.$gl-attributeName}*='-middle'] {
    align-self: center;
  }
  &[#{vars.$gl-attributeName}*='-bottom'] {
    align-self: flex-end;
  }
  &[#{vars.$gl-attributeName}*='-first'] {
    order: -1;
  }
  &[#{vars.$gl-attributeName}*='-last'] {
    order: 1;
  }
}
/************************
    GRID BY NUMBER
*************************/
@include mixins.makeGridByNumber(#{vars.$gl-gridName});
@each $mq-key, $mq-value in vars.$gl-mq-list {
  @include mixins.bp(#{$mq-key}) {
    //@media #{$mq-value} {
    @include mixins.makeGridByNumber(_#{$mq-key});
  }
}

/************************
    COLS SIZES
*************************/
@include mixins.makeCol(#{vars.$gl-colName});
@include mixins.makeOff(off);

@each $mq-key, $mq-value in vars.$gl-mq-list {
  @include mixins.bp(#{$mq-key}) {
    @include mixins.makeCol(_#{$mq-key});
    @include mixins.makeOff(_#{$mq-key});
    @include mixins.makeFirstLast(_#{$mq-key});
  }
}

/************************
    HIDING COLS
*************************/
@each $mq-key, $mq-value in vars.$gl-mq-list {
  @include mixins.bp(#{$mq-key}) {
    [#{vars.$gl-attributeName}*='#{$mq-key}-hidden'] {
      display: none !important;
    }
  }
}
