$grid-space-1: 5px;
$grid-space-2: 10px;
$grid-space-3: 15px;
$grid-space-4: 20px;
$grid-space-5: 30px;
$grid-space-6: 40px;
$grid-space-7: 60px;
$grid-space-8: 80px;
$grid-space-9: 100px;

$grid-item-width: 360px;
/** @deprecated use $grid-space-4 or any other $grid-space-** */
$grid-item-gap: $grid-space-4;

/*
* Grid
* Define lefty grid system
* Influencer card and post must have the same width ($grid-item-width)
* default content width is based on 4 columns
*
*                   gap / 2
*              ------------------
* **(gap / 2)**|***Influencer***|**(gap / 2)**
*              ------------------
*                   gap / 2
*
*/

$item-by-row: 4;
$grid-width-large: ($grid-item-width + $grid-item-gap) * $item-by-row;
$grid-width-medium: ($grid-item-width + $grid-item-gap) * ($item-by-row - 1);
$grid-width-small: ($grid-item-width + $grid-item-gap) * ($item-by-row - 2);

$grid-layout-padding: var(--grid-layout-padding);
$grid-layout-max-width: var(--grid-layout-max-width);
