@use 'sass:math';
@use 'sass:map';
@use 'sass:list';

@use 'gridlex-vars' as vars;
@use 'gridlex-preprocessing' as prep;

// Make the breakpoints
@mixin bp($breakpoint) {
  $query: map.get(vars.$gl-mq-list, $breakpoint);
  @if $query != null {
    @media (vars.$gl-mq-width: #{list.nth($query, 1)}) {
      @content;
    }
  }
}

// Function to generate the grid by number
@mixin makeGridByNumber($grid) {
  @for $i from 1 through vars.$gl-colCount {
    [#{vars.$gl-attributeName}*='#{$grid}-#{$i}']
      > [#{vars.$gl-attributeName}~='#{vars.$gl-colName}'],
    [#{vars.$gl-attributeName}*='#{$grid}-#{$i}']
      > [#{vars.$gl-attributeName}*='#{vars.$gl-colName}-'],
    [#{vars.$gl-attributeName}*='#{$grid}-#{$i}']
      > [#{vars.$gl-attributeName}*='#{vars.$gl-colName}_'] {
      $fraction: math.div(1, $i);

      flex-basis: map.get(prep.$gl-colFractions, $fraction);
      max-width: map.get(prep.$gl-colFractions, $fraction);
    }
  }
}

// Function to generate the grid by columns
@mixin makeCol($col) {
  [#{vars.$gl-attributeName}~='#{vars.$gl-gridName}'],
  [#{vars.$gl-attributeName}*='#{vars.$gl-gridName}-'],
  [#{vars.$gl-attributeName}*='#{vars.$gl-gridName}_'] {
    @for $i from 1 through vars.$gl-colCount {
      > [#{vars.$gl-attributeName}*='#{$col}-#{$i}'] {
        flex-basis: map.get(prep.$gl-colPortions, $i);
        max-width: map.get(prep.$gl-colPortions, $i);
      }
    }
  }
}

// Function to generate the -first & -last base on mq keys (as _lg-first)
@mixin makeFirstLast($mq) {
  [#{vars.$gl-attributeName}~='#{vars.$gl-gridName}'],
  [#{vars.$gl-attributeName}*='#{vars.$gl-gridName}-'],
  [#{vars.$gl-attributeName}*='#{vars.$gl-gridName}_'] {
    [#{vars.$gl-attributeName}*='#{$mq}-first'] {
      order: -1;
    }
    [#{vars.$gl-attributeName}*='#{$mq}-last'] {
      order: 1;
    }
  }
}

// Function to generate the offset
@mixin makeOff($off) {
  [#{vars.$gl-attributeName}~='#{vars.$gl-gridName}'],
  [#{vars.$gl-attributeName}*='#{vars.$gl-gridName}-'],
  [#{vars.$gl-attributeName}*='#{vars.$gl-gridName}_'] {
    @for $i from 0 through vars.$gl-colCount - 1 {
      > [data-push-left*='#{$off}-#{$i}'] {
        margin-left: map.get(prep.$gl-colPortions, $i);
      }
    }
  }

  [#{vars.$gl-attributeName}~='#{vars.$gl-gridName}'],
  [#{vars.$gl-attributeName}*='#{vars.$gl-gridName}-'],
  [#{vars.$gl-attributeName}*='#{vars.$gl-gridName}_'] {
    @for $i from 0 through vars.$gl-colCount - 1 {
      > [data-push-right*='#{$off}-#{$i}'] {
        margin-right: map.get(prep.$gl-colPortions, $i);
      }
    }
  }
}
