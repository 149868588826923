@use './variables/grid' as *;
@use './mixins/rem' as *;
@use './mixins/scrollbar' as *;

@mixin _nestor-colors {
  --black-050: rgba(24, 24, 24, 0.5);
  --black-008: rgba(24, 24, 24, 0.08);
  --black: rgb(24, 24, 24);

  --primary-color: var(--black);
  --primary-color-darker: var(--black);

  --primary-color-800: var(--black);
  --primary-color-500: var(--black);
  --primary-color-300: var(--grey-300);
  --primary-color-100: var(--grey-180);
  --primary-color-050: var(--grey-050);

  --link-primary-color: var(--teal-500);
  --link-primary-color-darker: var(--teal-800);

  --body-background-color: white;
  --button-group-active-background-color: var(--grey-050);
  --button-group-hover-background-color: var(--grey-020);
  --button-group-active-shadow: none;
}

@mixin _nestor-typo {
  --font: 'Inter';
  --fancy-font: 'Marcellus';

  --heading-xxl-font-size: #{rem(48px)};
  --heading-xxl-font-weight: 600;

  --heading-xl-font-size: #{rem(36px)};
  --heading-xl-font-weight: 600;

  --heading-1-font-size: #{rem(30px)};
  --heading-1-font-weight: 500;

  --heading-2-font-size: #{rem(24px)};
  --heading-2-font-weight: 500;

  --heading-3-font-size: #{rem(20px)};
  --heading-3-font-weight: 500;

  --heading-4-font-size: #{rem(16px)};
  --heading-4-font-weight: 500;

  --heading-5-font-size: #{rem(14px)};
  --heading-5-font-weight: 500;
}

@mixin _nestor-button-style {
  --button-small-font-size: #{rem(14px)};
  --button-border-radius: 20px;
  --button-height: 40px;
  --button-padding: 0 #{$grid-space-3};
  --button-font-weight: var(--font-weight-medium);
  --button-font-size: #{rem(14px)};

  --button-small-border-radius: 15px;
  --button-small-height: 32px;
  --button-small-padding: 0 #{$grid-space-3};
  --button-small-font-size: #{rem(12px)};

  --button-large-border-radius: 24px;
  --button-large-height: 48px;
  --button-large-padding: 0 #{$grid-space-4};

  --button-focus-color: var(--black);

  --button-primary-background-color: var(--black);
  --button-primary-disabled-background-color: var(--grey-300);
  --button-primary-disabled-font-color: white;
  --button-primary-hover-background-color: var(--grey-800);

  --button-secondary-font-solor: var(--black);
  --button-secondary-spinner-color: var(--grey-080);

  --button-group-height: 40px;
  --button-group-font-size: var(--body-1-font-size);
}

@mixin _nestor-input-style {
  --input-height: 40px;
  --input-line-height: 140%;
  --input-rounded-border-radius: 20px;
  --input-font-weight: 400;
  --input-border-radius: 8px;
}

@mixin _nestor-badge-style {
  --badge-font-color: var(--black);
  --badge-font-size: var(--body-1-font-size);
  --badge-line-height: 140%;
  --badge-font-weight: 400;
  --badge-height: 30px;
  --badge-border-radius: 15px;
  --badge-round-border-radius: var(--badge-border-radius);
}

@mixin _nestor-feedback-style {
  --feedback-info-icon-color: var(--teal-500);
  --feedback-warning-icon-color: var(--yellow-500);
  --feedback-error-icon-color: var(--red-400);
  --feedback-unavailable-icon-color: var(--grey-600);
  --feedback-success-icon-color: var(--green-500);

  --feedback-info-icon: info_feedback;
  --feedback-warning-icon: info_feedback;
  --feedback-error-icon: error_feedback;
  --feedback-unavailable-icon: error_feedback;
  --feedback-success-icon: success_feedback;
}

@mixin nestor-theme {
  @include _nestor-colors;
  @include _nestor-typo;
  @include _nestor-button-style;
  @include _nestor-input-style;
  @include _nestor-badge-style;
  @include _nestor-feedback-style;
  @include lefty-scrollbar;

  --lefty-card-border-radius: 16px;
}
