@use '../variables' as *;
@use '../mixins' as *;

.lefty-tooltip {
  position: absolute;
  z-index: 99999;
  opacity: 0;

  overflow: hidden;

  .message {
    /* respect return to line `\n` inside tooltip */
    white-space: pre-line;
    overflow-wrap: break-word;

    @include app-paragraph-default;
    color: $black;
  }

  box-sizing: border-box;
  padding: $tooltip-padding;

  max-width: $tooltip-max-width;
  min-width: none;

  &.large {
    max-width: $tooltip-max-width-large;
  }

  border-radius: $tooltip-border-radius;
  box-shadow: $tooltip-shadow;
  background-color: white;

  -webkit-transition: $tooltip-transition;
  -moz-transition: $tooltip-transition;
  -o-transition: $tooltip-transition;
  transition: $tooltip-transition;

  display: flex;
  flex-direction: column;
  gap: $grid-space-3;

  &.warning {
    background-color: $yellow-050;
  }

  &.empty {
    font-style: italic;
    color: $grey-600;
  }

  &.visible {
    opacity: 1;
  }
}
