@use '../variables' as *;
@use '../mixins' as *;

.lefty-feedback {
  padding: var(--feedback-padding);
  border-radius: var(--feedback-border-radius);

  display: inline-flex;
  align-items: flex-start;
  box-sizing: border-box;
  gap: $grid-space-2;

  .actions {
    display: flex;
    align-items: center;
    gap: $grid-space-2;

    &:empty {
      display: none;
    }
  }

  .container {
    display: flex;
    // special case of 2px
    padding: 2px 0px;
    flex-direction: row;
    align-items: flex-start;
    gap: $grid-space-4;
    flex: 1 0 0;

    box-sizing: border-box;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $grid-space-2;
    align-self: stretch;
  }

  .title {
    width: 100%;
  }

  &.has-title {
    .container {
      flex-direction: column;
    }

    p {
      padding-right: $grid-space-4;
    }
  }

  &:not(.has-title) {
    .title {
      display: none;
    }
  }

  .icon {
    color: var(--feedback-icon-color);
  }

  &.info {
    background-color: var(--feedback-info-color);

    .icon {
      color: var(--feedback-info-icon-color);
    }
  }

  &.warning {
    background-color: var(--feedback-warning-color);

    .icon {
      color: var(--feedback-warning-icon-color);
    }
  }

  &.error {
    background-color: var(--feedback-error-color);

    .icon {
      color: var(--feedback-error-icon-color);
    }
  }

  &.success {
    background-color: var(--feedback-success-color);

    .icon {
      color: var(--feedback-success-icon-color);
    }
  }

  &.unavailable {
    background-color: var(--feedback-unavailable-color);

    .icon {
      color: var(--feedback-unavailable-icon-color);
    }
  }
}
