@use 'sass:math';

@use '../variables' as *;
@use '../mixins' as *;

.lefty-radio {
  align-items: baseline;
  cursor: pointer;
  display: inline-flex;

  > .icon-container {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    width: var(--radio-size);
    height: var(--radio-size);

    box-sizing: border-box;

    border-style: solid;
    border-width: var(--radio-border-size);
    border-radius: var(--radio-border-radius);

    .round {
      height: var(--radio-round-size);
      width: var(--radio-round-size);
      border-radius: 50%;
    }
  }

  &:focus-visible {
    > .icon-container {
      @include focus-style;
    }
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;

    &.checked {
      > .icon-container {
        border-color: var(--radio-checked-disabled-border-color);
        background-color: var(--radio-checked-disabled-background-color);

        .round {
          background-color: var(
            --radio-round-checked-disabled-background-color
          );
        }
      }
    }

    &:not(.checked) {
      > .icon-container {
        border-color: var(--radio-unchecked-disabled-border-color);
        background-color: var(--radio-unchecked-disabled-background-color);

        .round {
          background-color: var(
            --radio-round-unchecked-disabled-background-color
          );
        }
      }
    }
  }

  &.checked {
    > .icon-container {
      border-color: var(--radio-checked-border-color);
      background-color: var(--radio-checked-background-color);

      .round {
        background-color: var(--radio-round-checked-background-color);
      }
    }
  }

  &:not(.checked) {
    > .icon-container {
      border-color: var(--radio-unchecked-border-color);
      background-color: var(--radio-unchecked-background-color);

      .round {
        background-color: var(--radio-round-unchecked-background-color);
      }
    }
  }

  > .content {
    align-items: center;
    flex: 1;
    min-width: 0px;

    @include app-body-1;
    color: $grey-800;
    margin: 0 $grid-space-1;
    overflow: hidden;
  }
}

.lefty-radio-group {
  display: flex;
  align-items: flex-start;

  &.outline {
    flex-direction: column;

    .lefty-radio {
      margin: 0;
      padding: $grid-space-4;
      border-radius: 8px;
      border: solid 1px $grey-080;

      width: 100%;
      box-sizing: border-box;

      &:first-child {
        margin-top: $grid-space-2;
      }

      &:not(:last-child) {
        margin-bottom: $grid-space-2;
      }

      .helper {
        margin-top: $grid-space-2;
        color: $grey-600;
      }

      > .content {
        margin: 0 $grid-space-2;
        overflow: visible;
      }
    }
  }
}
