@use './grid' as *;
@use '../mixins/rem' as *;

@mixin lefty-menu-variables {
  --nav-button-icon-color: var(--grey-800);
  --nav-button-background-color: transparent;
  --nav-button-border-radius: 8px;
  --nav-button-active-background-color: var(--accent-color);
  --nav-button-hover-background-color: white;
  --nav-button-size: 40px;

  --sidebar-background-color: var(--grey-020);
  --sidebar-border-color: var(--grey-080);
  --sidebar-border-radius: 12px;
  --sidebar-search-button-size: 36px;
  --sidebar-search-button-border-color: var(--base-border-color);

  --menu-item-padding: #{$grid-space-2};
  --menu-item-border-radius: 8px;
  --menu-item-background-color: transparent;
  --menu-item-font-color: var(--grey-800);
  --menu-item-font-size: var(--body-1-font-size);
  --menu-item-font-weight: var(--font-weight-regular);
  --menu-item-hover-font-color: var(--black);
  --menu-item-hover-background-color: white;
  --menu-item-active-font-weight: var(--font-weight-medium);
  --menu-item-active-font-color: var(--black);
  --menu-item-active-background-color: white;

  --tabs-gap: #{$grid-space-6};
  --tabs-height: 38px;
  --tab-item-inner-gap: #{$grid-space-1};
  --tab-item-font-color: var(--grey-600);
  --tab-item-font-size: #{rem(16px)};
  --tab-item-line-height: #{rem(24px)};
  --tab-item-font-weight: var(--font-weight-regular);
  --tab-item-active-font-color: var(--black);
  --tab-item-active-font-weight: var(--font-weight-medium);
  --tab-item-active-border-color: var(--black);
  --tab-item-hover-font-color: var(--black);
  --tab-item-hover-border-color: var(--grey-180);

  --tabs-small-height: 32px;
  --tabs-small-gap: #{$grid-space-5};
  --tab-item-small-font-size: var(--body-1-font-size);
  --tab-item-small-line-height: var(--body-1-line-height);
}
