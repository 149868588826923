@use './colors' as *;
@use './grid' as *;
@use './fonts' as *;
@use '../mixins/rem' as *;

@mixin lefty-input-variables {
  --input-height: 38px;
  --input-padding: #{$grid-space-2};

  --input-font-size: var(--body-1-font-size);
  --input-line-height: var(--body-1-line-height);
  --input-font-weight: var(--font-weight-regular);
  --input-font-color: var(--black);
  --input-placeholder-font-color: var(--grey-600);

  --input-icon-color: var(--grey-600);
  --input-icon-size: #{rem(20px)};

  --input-background-color: var(--grey-050);

  --input-border-size: 1px;
  --input-border-radius: 8px;

  --input-rounded-border-radius: 19px;

  --input-transition: width, background-color, color, border-color;
  --input-transition-duration: 300ms;

  --input-expanded-width: 170px;

  --input-disabled-background-color: var(--grey-020);
  --input-disabled-border-color: var(--grey-080);
  --input-disabled-font-color: var(--grey-300);
  --input-disabled-icon-color: var(--grey-300);

  --input-invalid-border-color: var(--red-400);
  --input-border-focus-color: var(--primary-color);

  --input-small-height: 24px;
  --input-small-padding: #{$grid-space-1};
}
