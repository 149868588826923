@use './colors' as *;
@use './grid' as *;

$al-card-horizontal-padding: $grid-space-5;
$al-card-padding: $grid-space-5;
$al-card-compact-padding: $grid-space-4;
$al-card-border: 1px solid $grey-080;
$al-card-border-shadow-hover: 0px 4px 14px 0px rgba(0, 0, 0, 0.08);
$al-card-border-radius: var(--lefty-card-border-radius);
$al-card-selectable-border-radius: var(--lefty-selectable-card-border-radius);
$al-card-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
$al-card-box-shadow-hover: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
$al-card-transition: box-shadow 300ms;
